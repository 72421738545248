/* ./src/index.css */

@tailwind base;
@tailwind components;

body {
  background-color: rgb(245, 245, 245);
}

.modal {
  background: white;
  border-radius: 5px;
  color: rgb(51, 51, 51);
  max-height: 620px;
  max-width: 500px;
  outline: none;
  padding: 10px;
}

.modal-fondo {
  background-color: rgba(0, 0, 0, 0.3);
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  position: fixed;
}

.avatars {
  display: flex;
  list-style-type: none;
  margin: auto;
  padding: 0px;
  flex-direction: row;
}

.avatars__item {
  background-color: #596376;
  border: 2px solid white;
  border-radius: 100%;
  color: #ffffff;
  display: block;
  font-family: sans-serif;
  font-size: 12px;
  font-weight: 100;
  height: 64px;
  width: 64px;
  line-height: 45px;
  text-align: center;
  transition: margin 0.1s ease-in-out;
  overflow: hidden;
  margin-left: -20px;
}

.avatars :first-child {
  z-index: 5;
}

.avatars :nth-child(2) {
  z-index: 4;
}

.avatars :nth-child(3) {
  z-index: 3;
}

.avatars :nth-child(4) {
  z-index: 2;
}

.avatars img {
  width: 100%
}

.avatars:hover .avatars__item {
  margin-right: 10px;
}

.ReactModal__Overlay {
  z-index: 1000;
}

.thin-stroke path {
  stroke-width: 1;
}

.shadow-bottom {
  box-shadow: 0px 6px 4px rgba(0, 0, 0, 0.25);
}

.shadow-card {
  box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.25);
}

.shadow-card-alert {
  box-shadow: 2px 5px 10px #EF4444;
}

.card-rounded {
  border-radius: 100px 10px 100px 19px;
}

.input-style>input {
  background-color: #eeeeee;
}

.PhoneInputInput {
  background-color: #eeeeee;
  color: #43413E;
}

.w-88 {
  width: 22rem;
}

.mirror {
  transform: scale(-1, 1);
}

/* .slider-section {
  display: grid;
  grid-template-columns: var(--page-margin) [center-start] 1fr [center-end] var(--page-margin);
}

.slider-section>* {
  grid-column: center;
} */

.cards-wrapper {
  grid-column: center-start / -1;
  overflow: auto;
  display: flex;
  padding-bottom: 1em;
}

.cards-wrapper>.card {
  background-color: #d0f0ee;
  color: #15928A;
  height: 20em;
  min-width: 13em;
  margin-right: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cards-wrapper>.card:last-child {
  margin-right: 0;
}

.cards-wrapper::after {
  content: "";
  display: block;
  min-width: var(--page-margin);
}

.horizontal-scroll-wrapper {
  margin: 0;
  overflow-y: auto;
  overflow-x: hidden;
}

.horizontal-scroll-card {
  display: block;
  /* transform: rotate(90deg); */
  /* transform-origin: right top; */
}

.selected-input-box {
  border: 1px solid transparent;
  /* border-image: linear-gradient(91.18deg, #EA0CA1, #FF1744) 1;
  -webkit-border-image: -webkit-gradient(91.18deg, #EA0CA1, #FF1744) 1; */
  background: linear-gradient(#fff, #fff) padding-box, linear-gradient(91.18deg, #EA0CA1, #FF1744) border-box;
  border-radius: 4px;
}

.selected-input-box-gray {
  border: 2px solid transparent;
  /* border-image: linear-gradient(91.18deg, #EA0CA1, #FF1744) 1;
  -webkit-border-image: -webkit-gradient(91.18deg, #EA0CA1, #FF1744) 1; */
  background: linear-gradient(#e6e6e6, #e6e6e6) padding-box, linear-gradient(91.18deg, #EA0CA1, #FF1744) border-box;
  border-radius: 4px;
}

.react-time-picker__clock {
  display: none;
}

.no-styles-input {
  outline: none;
  border: none;
  background-image: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.hide-scroll {
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}

.bottom-shadow {
  -moz-box-shadow: inset 0 -10px 20px -9px rgba(128, 128, 128, 0.1);
  -webkit-box-shadow: inset 0 -10px 20px -9px rgba(128, 128, 128, 0.1);
  box-shadow: inset 0 -10px 20px -9px rgba(128, 128, 128, 0.1);
}

.hide-scroll::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

@tailwind utilities;